<template>
  <Main>
    <ToolsPanel :breadCrumbFirst="'Templates'" :title="'Templates Edit Quick'"> </ToolsPanel>
    <ContentContainer>
      <div class="list">
        <div class="list__toggles">
          <div
            @click="handleChangeSortStatus('all')"
            :class="['list__toggle', sort === 'all' && 'active']"
          >
            All
          </div>
          <div
            @click="handleChangeSortStatus('color')"
            :class="['list__toggle', sort == 'color' && 'active']"
          >
            Need to Choose Color
          </div>
          <div
            @click="handleChangeSortStatus('style')"
            :class="['list__toggle', sort == 'style' && 'active']"
          >
            Need to Choose Style
          </div>
        </div>
        <div v-if="templatesList.length" class="quick__list">
          <div class="quick__header">
            <div class="quick__header-item">ID</div>
            <div class="quick__header-item">Preview</div>
            <div class="quick__header-item">Name</div>
            <div class="quick__header-item">Color</div>
            <div class="quick__header-item">Style</div>
            <div class="quick__header-item"></div>
          </div>
          <div v-for="item in templatesList" :key="item.id" class="quick__item">
            <div class="quick__item-name">
              <router-link :to="`/template/edit/${item.id}`">{{ item.id }}</router-link>
            </div>
            <div class="quick__item-img">
              <img v-if="item.images.length" :src="item.images[0][0].url.preview[0]" alt="" />
            </div>
            <div class="quick__item-name">{{ item.name }}</div>
            <div class="quick__item-color">
              <div class="quick__item-chosen">
                <div v-if="item.colors.length" class="quick__item-chosen-item">
                  <span v-for="(color, index) in item.colors[0]" :key="color.id"
                    >{{ index > 0 ? "| " : "" }}{{ color.name }}
                  </span>
                </div>
                <div v-else class="quick__item-chosen-item">Choose a Color</div>
                <SelectArrow />
                <div class="quick__item-chosen-list">
                  <div class="input-container checkbox-container">
                    <div
                      v-for="i in templatesColorList"
                      :key="i.id"
                      class="checkbox-container__item"
                    >
                      <MainCheckbox :label="i.name" :id="i.id + ''" :set-value="item.color" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="quick__item-style">
              <div class="quick__item-chosen">
                <div v-if="item.style" class="quick__item-chosen-item">
                  {{ item.style.name }}
                </div>
                <div v-else class="quick__item-chosen-item">Choose a Style</div>
                <SelectArrow />
                <div class="quick__item-chosen-list">
                  <div class="input-container checkbox-container">
                    <InputRadio
                      :set-value="item"
                      :id="'styleId'"
                      :values="templatesStyleList"
                      :name="'name'"
                      :value="'id'"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="quick__item-button-container">
              <div @click="updateTemplate(item)" class="quick__item-button">Save Changes</div>
            </div>
          </div>
          <div class="pagination">
            <Pagination
              :maxPage="templates.lastPage"
              :page="page"
              :dataList="templates"
              :urlList="'TEMPLATE_QUICK_EDIT'"
            />
          </div>
        </div>
        <div class="list__no-have" v-else>No Templates found by this parameter.</div>
      </div>
    </ContentContainer>

    <MainModal
      v-if="modalSuccess"
      :on-close="
        () => {
          this.modalSuccess = false;
        }
      "
    >
      <div class="list__success-delete">Completed successfully</div>
    </MainModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ClickOutside from "vue-click-outside";
import templatesApi from "~/api/templates";
import referencesApi from "~/api/references";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import Pagination from "~/components/molecules/Pagination.vue";
import MainModal from "~/components/molecules/MainModal.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";
import InputRadio from "~/components/atoms/inputs/InputRadio.vue";
import SelectArrow from "~/assets/images/icons/select-arrow.svg";

export default {
  name: "TemplatesEditQuick",
  metaInfo: {
    title: "Templates Edit Quick",
  },
  data() {
    return {
      modalSuccess: false,
      templates: {},
      templatesList: [],
      sort: "all",
    };
  },
  directives: {
    ClickOutside,
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    Pagination,
    MainModal,
    MainCheckbox,
    InputRadio,
    SelectArrow,
  },
  watch: {
    $route(to, from) {
      this.getTemplatesList();
    },
  },
  mounted() {
    this.getTemplatesList();
    this.getStyle();
  },
  computed: {
    ...mapState("templates", ["error", "loadingProcess"]),
    ...mapState("references", ["templatesStyleList", "templatesColorList"]),
    page() {
      return +this.$route.params.page;
    },
  },
  methods: {
    getTemplatesList() {
      let url = `?page=${this.page}`;
      if (this.sort == "color") {
        url += `&doesntHaveColors=1`;
      }
      if (this.sort == "style") {
        url += "&doesntHaveStyles=1";
      }
      try {
        referencesApi.getTemplatesColorAll().then((res) => {
          const colorList = res.data.items;
          this.$store.commit("references/setTemplatesColorList", colorList);
          this.$store.commit("templates/setLoading", true);
          templatesApi
            .getTemplatesQuickList(url)
            .then((res) => {
              this.templates = res.data[0];
              this.templatesList = res.data[0].data;

              for (let i = 0; this.templatesList.length > i; i++) {
                const data = this.templatesList[i];
                const color = data.colors;
                this.$set(this.templatesList[i], "color", {});
                if (color != undefined && color.length > 0) {
                  for (let b = 0; color[0].length > b; b++) {
                    this.$set(this.templatesList[i].color, color[0][b].id, true);
                  }
                }
                for (let a = 0; colorList.length > a; a++) {
                  if (this.templatesList[i].color[colorList[a].id] == undefined) {
                    this.$set(this.templatesList[i].color, colorList[a].id, false);
                  }
                }
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.$store.commit("templates/setLoading", false);
            });
        });
      } catch (error) {
        console.log(error);
      }
    },
    getStyle() {
      this.$store.dispatch("references/getTemplatesStyleAll");
    },
    handleChangeSortStatus(data) {
      this.sort = data;
      this.getTemplatesList();
    },
    updateTemplate(item) {
      this.$store.commit("templates/setLoading", true);
      const data = {};
      if (item.styleId != null) {
        data.style_id = item.styleId;
      }

      const color = Object.keys(item.color);
      if (color.length) {
        let colorSend = [];
        for (let i = 0; color.length > i; i++) {
          if (item.color[color[i]] == true) {
            colorSend.push(color[i]);
          }
        }
        if (colorSend.length > 0) {
          data.colors = colorSend;
        }
      }

      templatesApi
        .editQuickTemplate(`/${item.id}`, data)
        .then((res) => {
          this.getTemplatesList();
          this.modalSuccess = true;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.commit("templates/setLoading", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__toggles {
    display: flex;
    flex-wrap: wrap;
  }

  &__toggle {
    margin-right: 3px;
    margin-bottom: 10px;
    padding: 10px 15px;
    font-size: 20px;
    font-weight: bold;
    background: $mainDisabled;
    color: $mainColor;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &:first-child {
      border-radius: 7px 0 0 7px;
    }

    &:last-child {
      border-radius: 0 7px 7px 0;
    }

    &.active {
      background: $mainSecondColor;
      color: $g-color-white;
    }
  }
}
.quick {
  &__item {
    padding: 20px 0;
    border: 1px solid grey;
    border-top: none;
    display: grid;
    grid-template-columns: 0.8fr 1fr 2fr 2fr 2fr 2fr !important;
    padding: 10px 20px;
  }
  &__header {
    padding: 20px;
    background: #3a80f9;
    display: grid;
    grid-template-columns: 0.8fr 1fr 2fr 2fr 2fr 2fr !important;
  }
  &__header-item {
    color: #fff;
  }
  &__item-button-container {
    display: flex;
    align-items: center;
  }

  &__item-button {
    padding: 10px 20px;
    color: #fff;
    border: 2px;
    background: #3a80f9;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
  &__item-img {
    img {
      width: 100%;
    }
  }
  &__item-img {
    padding-right: 10px;
  }

  &__item-color,
  &__item-style {
    display: flex;
    align-items: center;
    padding-right: 20px;
  }

  &__item-name {
    font-size: 16px;
    padding-right: 10px;
    display: flex;
    align-items: center;
  }

  &__item-chosen {
    position: relative;
    padding: 10px 30px 10px 20px;
    border: 1px solid gray;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;

    &:hover {
      .quick__item-chosen-list {
        display: block;
      }
    }

    svg {
      width: 20px;
      height: 20px;
      position: absolute;
      top: calc(50% - 10px);
      right: 10px;
    }
  }

  &__item-chosen-list {
    position: absolute;
    display: none;
    background: #fff;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    top: 100%;
    z-index: 1;
    left: 0;
    width: 200px;
    border-radius: 10px;
    max-height: 200px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px; /* Ширина скролла */
    }

    /* Трек (фон) скролла */
    &::-webkit-scrollbar-track {
      background-color: transparent; /* Серый цвет фона */
    }

    /* Бегунок (ползунок) скролла */
    &::-webkit-scrollbar-thumb {
      background-color: #888; /* Серый цвет бегунка */
      border-radius: 4px; /* Закругление углов бегунка */
    }

    /* При наведении на бегунок */
    &::-webkit-scrollbar-thumb:hover {
      background-color: #555; /* Темнее серый цвет */
    }

    .checkbox-container__item {
      padding-bottom: 10px;
    }
  }
}
</style>
